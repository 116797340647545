body {
  font-family: "Open Sans","robotolight","Helvetica Neue",Helvetica,Helvetica,Arial,sans-serif;
  height: auto;
 }
 @media print, screen and (min-width: 64em){
  .grid-x>.large-4 {
      width: 31.33333%;
      margin-right: 25px;
      margin-top: 15px;
      margin-bottom: 15px;
      border-radius: 50px;
  }
 }
 .get_in_touch_sticky{
    position: relative !important;
    top: 19px;
    left: 10rem;
    width: 35%;
    height: 55px;
    background-color: #ffc10a;
    color: #fefefe !important;
    z-index: 99999;
 }
 @keyframes mymove {
  from {left: 0px;}
  to {left: 500px;}
 }
#wrapper .line{
  animation: 2s linear 0s normal none infinite running anm2;
}
#wrapper .f1.s2{
  opacity:1;
}
#wrapper .s2{
  opacity:0;
}
#wrapper .line{
  animation: 2s linear 0s normal none infinite running anm2;
  color: #ffb600;
}
#wrapper .f1{
  overflow:hidden;
  animation:anm1 2s steps(60,end);
  }
  #wrapper p{
    margin:0;
     width:310px;
     font-size:1.2rem;
  }
@keyframes anm1{
  0%{width:0px;}
}
@keyframes anm2{
  50%{opacity:0}
  100%{opacity:1}
}
footer {
  background: #f2f2f2;
  padding: 15px;
  color: #0f1010;
  font-size: 1rem;
}
.footerDesktop{
  position: relative;
  width: 100%;
  height: 65px;
  display: flex;
  border-top: 2px #8a8a8a solid;
}
.btn {
  background: #3498db;
  background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
  background-image: -moz-linear-gradient(top, #3498db, #2980b9);
  background-image: -ms-linear-gradient(top, #3498db, #2980b9);
  background-image: -o-linear-gradient(top, #3498db, #2980b9);
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
}

.btn:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
}
.readMore{
  width: 30%;
  height: 50px;
  justify-content: end;
  display: flex;
  float: right;
  clear: both;
}
.bshadow{
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}
button, .button {
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  font-family: inherit;
  font-weight: normal;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1em;
  border: 0;
  color: #fff;
  border-radius: 20px;
  font-weight: 600;
}

.orbit-slides-container{
  height: auto !important;
}

.orbit-container .orbit-slides-container li.active{
  position:static;
}
.App {
  text-align: center;
}
.containerBg{
  background: url("./body_bgg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: -150px;
}

.bgOrange{background-color: #ffb600;}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.FormCon{
  border-radius: 5px;
  background: rgb(8 118 204 / 64%);
  padding-top: 15px;
}
.width50{
  top: 20% !important;
}
.socialConMobile{
  position: fixed;
  top: calc(50% - .5em);
  right: 0px;
  z-index: 9999;
}
ul ul {
  list-style-type: circle !important;
}
.FormConMobile {
  position: absolute;
  top: 9.4rem;
  right: 0rem;
  background: rgb(23 121 186 / 75%);
  padding-top: 20px;
  padding-bottom: 15px;
  width: 45%;
  border-radius: 15px;
}
.FormMobile {
  background: rgb(23 121 186 / 75%);
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;
  border-radius: 5px;
}
.headerContactTab{
  padding: 30px 8px;
  display: inline-flex;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffb600;
}
.spanGetTouchTxt{
  height: 0 !important;
  margin: 0 auto;
  margin-bottom: 15px;
}
.logoHolder{
  background: #fefefee3;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: 2;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.headingH1{font-size: 2rem !important;font-weight: 600;}
.headingH2{font-size: 18px !important;font-weight: 600; white-space: pre-wrap !important;line-height: 1.5 !important;}
.headingH3{font-size: 16px !important;font-weight: 600;}
.headingH4{font-size: 14px !important;font-weight: 600;}

.lodoc{
  list-style-type: disclosure-closed;
}
.tabsContentHeading{
  text-align: center;
  width: 70% !important;
  margin: 0 auto;
}

.tabsContentContainer{
  max-width: 84rem;
  padding: 5px;
  margin: 0 auto;
  text-align: left;
}
.tabs-title>a {
  font-size: 12px;
  font-weight: 600;
  color: #4cc5f3;
  padding: 1.25rem 1rem;
}
.borderOrange70{
  display: block;
    width: 70px;
    height: 24px;
    border: 4px solid #ffb600;
    border-bottom: 0;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.socialImageHolder{
  width: 30%;
  margin-left: 5px;
}
.query1Form {
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.card-img, .card-img-bottom, .card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}
.inner {
  overflow: hidden;
}
.logoMain{
  width: 10%;
  height: 100px;
  display: block;
  text-indent: -9999px;
  margin-bottom: 1.1rem;
}
.title-bar{
  background: #3498db;
}
.mobileMenu{
  padding: 7px 10px;
  border-radius: 4px;
  top: 2px;
  position: absolute;
  right: 2px;
  display: block;
  height: 6.5%;
}
.mobileMenuPM{
  padding: 15px;
  margin: 5px;
}
.menu-icon::after {
  -webkit-box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe, 0 21px 0 #fefefe;
  box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe, 0 21px 0 #fefefe;
}
._heading:after {
  content: "";
  height: 5px;
  background: -webkit-linear-gradient(0deg,#00baf2 50%,#ffaa00 0);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.menu .is-active>a {
  background: #3b3b3b;
  color: #fefefe;
}
.top-bar, .top-bar ul {
  background-color: #f1f1f1cc;
}
.is-dropdown-submenu {
  top: 2.6rem;
  left: 0%;
}
.is-dropdown-submenu-parent.opens-right>.is-dropdown-submenu {
  top: 0px;
}
[data-whatinput=mouse] .dropdown.menu a {
  font-weight: 600;
  font-size: .9rem;
  color: #1979c1;
  line-height: 1.1rem;
}
.dropdown .is-dropdown-submenu a {
  padding: .7rem .4rem;
}
.dropdown .is-dropdown-submenu a:hover {
  background: #3b3b3b;
  color: #fefefe;
}
.accordion-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  color: #14679e;
}
.headingBorderBottom{
  border: 4px solid #ffb600;
    border-bottom: 0;
    display: block;
    width: 200px;
    height: 0px !important;
}
.alignLeft{
  text-align: left;
  text-align: -webkit-left;
}

.height70{
  height: auto !important;
}
.padding11{
  padding: 11px;
}
.paddingRight30{
  padding-right: 30px;
}
.marginTop10{
  margin-top: 10px;
}
.marginTop20{
  margin-top: 2rem !important;
}
.marginBottom40{
  margin-bottom: 4rem !important;
}
.marginBottom20{
  margin-bottom: 2rem !important;
}
.marginBottom10{
  margin-bottom: 1rem !important;
}
.marginRight10{
  margin-right: 1rem !important;
}
.marginLeft5{
  margin-left: .5rem !important;
}
.addressTxt{
  font-size: 1.2rem;
    font-weight: 700;
    text-align: left;
    margin-left: 20px;
}
.display{
  display: block;
}
.lineHeight1_2{
  line-height: 1.2;
}
.borderTop2{border-top: 2px outset rgb(10 10 10 / 70%);}
.desktop .orbit-next, .desktop .orbit-previous {
  padding: 1rem;
  color: #fefefe;
  background: #0a0a0a38;
  border-radius: 5px;
  top: 50%;
}
.leftFooter{
  float:left;
  width: 70%;
}
.rightFooter{
  float:right;
  width: 30%;
  padding: .5rem 1rem;
}
.leftFooter a:focus, .leftFooter a:hover {
  color: #ff8a08;
}
.leftFooter a{
  color: #1979c1;
  cursor: pointer;
}
.App-link {
  color: #61dafb;
}

@keyframes gst-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
